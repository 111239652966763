@import url('https://fonts.googleapis.com/css?family=ABeeZee:400,700');

* {
  margin: 0;
  padding: 0;
  font-family: 'ABeeZee', sans-serif;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #000000 30%, #1b5baf 50%) !important;
  background-attachment: fixed;
}

h1 {
  font-size: 5.4rem;
}
h2 {
  font-size: 2.4rem;
}
h1, h2, p, li.nav-item, a:link, a:visited, a:active {
  color: #e0e1dd;
  font-weight: 700;
}
p {
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem 0;
}
a:hover {
  color: #ffffff;
  cursor: pointer;
}
a:link {
  font-size: 1.2rem;
  text-decoration: none;
  margin-right: 20px;
}

.wurklo__text {
  color: #e0e1dd;
}

/* note: styles target reactstrap elements */

/* navbar */
nav {
  background-color: #000000;
}

.navbar {
  display: flex;
  z-index: 1;
  transition: 0.5s ease-in;
  opacity: 1;
}
.navbar-hide {
  pointer-events: none;
  opacity: 0;
  transition: 0.5s ease-out;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.2rem;
  padding-right: 15px;
  letter-spacing: 1.2px;
}
.navbar-brand {
  display: flex;
  align-items: center;
  font-weight: 700;
}
img.navbar-coin {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.divider {
  border-left: 1px solid #e0e1dd;
  height: 30px;
  margin-left: 2rem;
  display: inline-flex;
}
  
  /* landing component background-image: linear-gradient(to left, #1b5baf, #3f4548);*/
.landing {
  padding-top: 5rem;
  width: 100%;
}

.contract {
  font-size: 0.6rem;
  background-color: rgb(90, 90, 90);
}

.contract:hover {
  font-size: 0.6rem;
  background-color: #1DA1F2;
}

.solscan-link {
  color: #6ae7ad;
}

.solscan-link:hover {
  transition: 300ms transform ease-in !important;
  transform: scale(1.03) !important;
}

.landing__signupButton:hover {
  background-color: #000000 !important;
}

/* Coin Learn more page */
.viewport100 {
  min-height: 70vh;
}

.font-small {
  font-size: 0.8rem;
}

/* About Page */
.founderVideo {
  border-radius: 1rem;
  width: 70%;
}

/* app  */
.preview__app {
position: relative;
}
.preview__appLaptop > img{
  max-height: 40rem;
  object-fit: contain;
  z-index: 0;
}
.preview__appMobile > img {
  max-height: 30rem;
  object-fit: contain;
  z-index: 1;
}
.preview__appMobile {
  position: absolute;
  top: 20%;
  right: 8%;
}

/* about component with cards */
.about__landing {
  color: #e0e1dd;
}
.about {
  color: #e0e1dd;
  text-align: center;
}
#about {
  color: #e0e1dd;
}
.card { 
  border: 0px;
}
.card-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: #3f4548;
}
.card-text {
  color: #3f4548;
}

.nft {
  width: 19.5rem;
  border-radius: 1rem;
}

/* preview of app component */
.preview-bg {
  background: #B1DDF1;
}

.magiceden__img {
  width: 5rem;
  height: auto;
}

.solseaRaydium__logos {
  width: 10rem;
  height: auto;
}

.anychart-credits {
 display: none;
}

.preview__feature {
  border-radius: 1rem;
}

/* Team component */
.team-img {
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
  object-fit: fill;
}

.team-text {
  color: #B1DDF1;
}

.team-container {
  color: #e0e1dd;
}

.partner-container {
  color: #e0e1dd;
}

.partner-img {
  border-radius: 50%;
  height: 15rem;
  width: 15rem;
  object-fit: fill;
}

/* ending component */
#app-content, #creator-content {
  color:#e0e1dd;
}
#app-preview {
  color: #e0e1dd;
  font-weight: 700;
  padding-bottom: 1rem;
}
.contact {
  background: #1b5baf;
  padding-left: 7rem;
}
#creator, #contact, #roadmap {
  color: #e0e1dd;
  padding-bottom: 2rem;
}

.cards {
  width: 20rem;
}
.cards2 {
  width: 20rem;
  background: #1b5baf;
}
.basket:hover {
  cursor: pointer;
  color: #ff0000 !important;
  transition: 300ms transform ease-in;
  transform: scale(1.03);
}
.basket:hover > .MuiSvgIcon-root {
  color: #ff0000 !important;
}
.basket:hover > .basketQuantity {
  color: #ff0000 !important;
}
.actionItem:hover {
  cursor: pointer;
  color: #ff0000 !important;
}
.product-img {
  height: 5rem;
}
.coinWurk {
  color: #ff0000;
  text-decoration: line-through;
}
.stripePayment__modalFooter {
  flex: none;
}
.formgroup {
  margin-bottom: 0.6rem !important;
}
.paymentOption__button:hover {
  cursor: pointer;
}

/* footer component */
footer {
  background-image: linear-gradient(to top, #000000 85%, #1b5baf 99%);
}
.fa-fw {
  color: #e0e1dd;
}

.facebook {
  transition: 300ms transform ease-out;
}
.facebook:hover {
  color: #4267B2;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.twitter {
  transition: 300ms transform ease-out;
}
.twitter:hover {
  color: #1DA1F2;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.telegram {
  transition: 300ms transform ease-out;
}
.telegram:hover {
  color: #0088CC;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.reddit {
  transition: 300ms transform ease-out;
}
.reddit:hover {
  color: #FF4500;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.instagram {
  transition: 300ms transform ease-out;
}
.instagram:hover {
  color: #F77737;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.discord {
  transition: 300ms transform ease-out;
}
.discord:hover {
  color: #5865F2;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.youtube {
  transition: 300ms transform ease-out;
}
.youtube:hover {
  color: #FF0000;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.tiktok {
  transition: 300ms transform ease-out;
}
.tiktok:hover {
  color: #EE1D52;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}
.medium:hover {
  color: #1b5baf;
  transition: 300ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

/* button styles for all buttons on page */
#connect-button:hover, #roadmap-button:hover, #timeline-button:hover, #whitepaper-button:hover {
  background: #ffffff;
  transition: 300ms transform ease-in;
  transform: scale(0.95);
  color: #000000;
}
/* ******change all buttons to this class******* */
.button {
  font-size: 1.2rem;
  padding: 1rem;
  border: 0px;
  margin-right: 15px;
  color: #3f4548;
  background: #e0e1dd;
  border-radius: 1rem;
  transition: 300ms transform ease-out;
}
.button:hover {
  background: #ffffff;
  transition: 300ms transform ease-in;
  transform: scale(1.03);
  color: #000000;
}
.button__connect {
  color: #3f4548;
  background: #e0e1dd;
  padding: .25rem;
  width: 150px;
}
.button__connect:hover {
  background: #ffffff;
  transition: 300ms transform ease-in;
  transform: scale(1.03);
  color: #000000;
}
#launch {
  color: #B1DDF1;
}
.timeline-button {
  color: #3f4548;
  background: #e0e1dd;
}
.timeline-button:hover {
  background: #ffffff;
  transition: 500ms transform ease-in;
  transform: scale(1.03);
  color: #000000;
}
#whitepaper-button, #roadmap-button {
  font-size: 1.2rem;
  padding: 1rem;
  border: 0px;
  color: #3f4548;
  background: #e0e1dd;
  border-radius: 1rem;
  transition: 500ms transform ease-out;

}
#card-button {
  background: #e0e1dd;
  color: #3f4548;
  letter-spacing: .8px;
}
#card-button:hover {
  background: #ff0000 !important;
  color: #e0e1dd;
  border: none;
  transition: 300ms transform ease-in;
  transform: scale(1.02);
}
.btn-secondary {
  font-weight: 700;
  border: none;
}

/* image styling */
img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
#mobile {
  height: 25rem;
  max-width: fit-content;
}
.cardImg {
  width: 100%;
}

/* media queries */
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

@media screen and (max-width: 1000px) {
  .founderVideo {
    border-radius: 1rem;
    width: 100%;
  }
  .cards {
    width: 20rem;
    background: #3f4548 !important;
  }
  .cards2 {
    width: 20rem;
    background: #1b5baf;
  }
  h1 {
    font-size: 2.6rem;
  }
  h2, #launch {
    font-size: 1.6rem;
    line-height: 1.8em;
    margin-top: 20px;
  }
  .landing {
    height: auto;
    padding-top: 0;
  }
  #whitepaper-button, #roadmap-button {
    margin-bottom: 1rem;
  }
  #about {
    font-size: 1.7rem;
    line-height: 1.8em;
  }
  .card {
    width: auto;
    height: auto;
    margin-bottom: 50px;
  }
  .card-text, #app-content {
    font-size: 1.2rem;
  }
  #timeline-button {
    display: none;
  }

  .preview__appLaptop > img{
    max-height: 40rem;
    object-fit: contain;
    z-index: 0;
  }
  .preview__appMobile > img {
    max-height: 10rem;
    object-fit: contain;
    z-index: 1;
  }
  .preview__appMobile {
    position: absolute;
    top: 20%;
    right: 4%;
  }
}

@media screen and (min-width: 771px) and (max-width: 1200px) {

}

@media screen and (min-width: 577px) and (max-width: 770px) {

}

@media screen and (min-width: 660px) and (max-width: 800px) {
  ul.timeline {
    right: 6rem;
  }
}