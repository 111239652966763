.roadmap {
  /* background-image: linear-gradient(to top, #000000 85%, #1b5baf 99%); */
}

.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: #3f4548;
  background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
  background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
  background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(224, 225, 221) 8%, rgb(224, 225, 221) 92%, rgba(80,80,80,0) 100%);
  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  background: #1b5baf;
  color: #e0e1dd;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -50px;
  content: ' ';
  display: block;
  width: 25px;
  height: 25px;
  margin-top: -10px;
  background: #1b5baf;
  border-radius: 10px;
  border: 4px solid #3f4548;
  z-index: 10;
}

.direction-r .flag:before {
  left: -50px;
}

.direction-l .flag:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-left-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.direction-r .flag:after {
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  margin-top: -8px;
  border: solid transparent;
  border-right-color: rgb(248,248,248);
  border-width: 8px;
  pointer-events: none;
}

.desc {
  margin: 1em 0.75em 0 0;
  font-size: 1em;
  font-style: italic;
  line-height: 1.5em;
  color: #e0e1dd;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}


@media screen and (max-width: 660px) {

  .timeline {
      width: 100%;
      padding: 4em 0 1em 0;
  }

  .timeline:before {
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(119, 119, 119) 8%, rgb(119, 119, 119) 92%, rgba(80,80,80,0) 100%);
  }

  .timeline li {
      padding: 2em 0;
  }

  .direction-l,
  .direction-r {
      float: none;
      width: 100%;
      text-align: center;
  }

  .flag-wrapper {
      text-align: center;
  }

  .flag {
      background: #1b5baf;
      z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
      left: 50%;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: -9px;
      background: #e7f5d9;
      border-radius: 10px;
      border: 4px solid #3f4548;
      z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color: rgb(255,255,255);
      border-width: 8px;
      pointer-events: none;
  }

  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;      
      z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }

}